"use client";

import React from "react";

import { Box, Button, Flex, Group, Text,} from "@mantine/core";

import { DynamicScalingViewport } from "@vorderseite/utils/viewport";

type OptionModal = {
    icon: React.JSX.Element;
    open: () => void;
};

type MainModal = {
    label: React.ReactNode;
    icon: React.JSX.Element;
    fontSize: string | number;
    open: () => void;
}

interface ManagementStyledProps extends React.PropsWithChildren {
    title?: string | undefined;
    openMainModal?: MainModal | undefined;
}

export default function ManagementStyled(props: ManagementStyledProps): Readonly<React.ReactElement<ManagementStyledProps>> {
    void DynamicScalingViewport();

    return (
        <Flex
            w={'100%'}
            h={'100%'}
            direction={'column'}
            align={'center'}
            wrap={'nowrap'}
        >
            <Group
                component={'div'}
                w={'100%'} h={'100%'}
                px={38} mih={86} mah={86}
                justify={'space-between'}
                align={'center'}
                style={{
                    borderBottom: '1px solid rgb(236, 236, 236)'
                }}
            >
                <Text
                    fw={600}
                    fz={30}
                >
                    {props.title !== undefined ? props.title : "หน้าจัดการระบบ"}
                </Text>
                <Group>
                    {props.openMainModal !== undefined && (
                        <Button
                            variant={'filled'}
                            color={'#f2b105'}
                            size={'md'}
                            radius={'md'}
                            c={'dark'}
                            onClick={props.openMainModal.open}
                        >
                            <Group gap={8}>
                                {props.openMainModal.icon}
                                <Text fz={props.openMainModal.fontSize} fw={700}>{props.openMainModal.label}</Text>
                            </Group>
                        </Button>
                    )}
                </Group>
            </Group>
            <Box w={'100%'} h={'100%'} p={40}>
                {props.children}
            </Box>
        </Flex>
    );
}
